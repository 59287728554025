<template>
  <div>
  <!-- //el-dialog element-ui中引入的组件  对话框 -->
  <!-- //visible.sync属性了解于elmentui中对话框介绍 -->
    <el-dialog :visible.sync="outerVisible" title="请完成安全验证"  :close-on-click-modal='false'  :modal-append-to-body='false' width="350px">
      <slide-verify
        ref="slideblock"
        @again="onAgain"
        @fulfilled="onFulfilled"
        @success="onSuccess"
        @fail="onFail"
        @refresh="onRefresh"
        :slider-text="text"
        :accuracy="accuracy"
      ></slide-verify>
      <!-- <button @click="handleClick">在父组件可以点我刷新哦</button>
      <div>{{ msg }}</div> -->
    </el-dialog>
  </div>
</template>
<style  lang="less" scoped>
.login-click-verification{
     background-color: rgb(232,240,254);
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    text-align: center;
    font-size: 2vh;
    line-height: 2.5;
    color: #409EFF;
    cursor: pointer;
    margin: 8px 0;
}
/deep/.el-dialog__header{
  display:none!important
}
</style>
<script>
export default {
  name: 'App',
  data() {
    return {
      msg: '',
      text: '向右滑动->',
      outerVisible: false,
      // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
      accuracy: 5,
    }
  },
  props: {
      timeMethod: {
        type: Function,
        default: null
      }
    },
  methods: {
    onSuccess() {
      this.msg = 'login success'
      //滑动验证成功之后关闭弹出的窗口
      this.outerVisible = false

      // 滑块弹框关闭，开始倒计时
       if (this.timeMethod) {
          this.timeMethod();
        }
      this.$refs.slideblock.reset()
    },
    onFail() {
      this.msg = ''
    },
    onRefresh() {
      this.msg = ''
    },
    onFulfilled() {
    },
    onAgain() {
      this.msg = 'try again'
      // 刷新
      this.$refs.slideblock.reset()
    },
    handleClick() {
      this.$refs.slideblock.reset()
    },
  },
}
</script>

